import axios from '@/plugins/axios'

export default {

  async list (surveyId, data = {}) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`surveys/${surveyId}/questions?${params}`)
  },

  async get (surveyId, questionId) {
    return await axios.get(`surveys/${surveyId}/questions/${questionId}`)
  },

  async insert (surveyId, data) {
    return await axios.post(`surveys/${surveyId}/questions`, data)
  },

  async update (surveyId, questionId, data) {
    return await axios.put(`surveys/${surveyId}/questions/${questionId}`, data)
  },

  async delete (surveyId, questionId) {
    return await axios.delete(`surveys/${surveyId}/questions/${questionId}`)
  },

}
