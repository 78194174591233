<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn
            class="mr-2"
            dark
            to="/pesquisas"
          >
            <v-icon
              left
              v-text="'mdi-chevron-left'"
            />
            Voltar
          </v-btn>
        </v-col>
      </v-row>

      <v-sheet
        elevation="2"
        class="mt-4"
      >
        <v-progress-linear
          v-if="loading"
          color="primary"
          top
          indeterminate
        />
        <div
          v-if="survey"
          class="pa-3"
        >
          <v-row>
            <v-col>
              <h1 class="display-1">
                <b>{{ survey.title }}</b>
                <small class="ml-1"><i>#{{ survey.id }}</i></small>
              </h1>
              <p class="mb-0 body-2">
                {{ survey.description }}
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col>
              <v-btn
                color="success"
                dark
                small
                @click="openDialogQuestion()"
              >
                <v-icon
                  left
                  small
                  v-text="'mdi-plus'"
                />
                Adicionar Pergunta
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-sheet
                elevation="0"
                outlined
              >
                <v-simple-table
                  dense
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Título
                        </th>
                        <th class="text-left">
                          Próxima
                        </th>
                        <th class="text-left">
                          Tipo
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-if="questions.length <=0 "
                      >
                        <td
                          colspan="4"
                          class="title"
                        >
                          <i>Nenhuma pergunta encontrada</i>
                        </td>
                      </tr>
                      <tr
                        v-for="(question, index) in questions"
                        :key="index"
                      >
                        <td>{{ question.title }}</td>
                        <td>
                          <v-tooltip
                            v-if="question.nextQuestion"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-text="'mdi-arrow-decision-auto'"
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <span>{{ question.nextQuestion.title }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ question.typeOfQuestion.description }}</td>

                        <td class="text-right">
                          <v-btn
                            icon
                            small
                            @click="openDialogQuestion(question)"
                          >
                            <v-icon
                              v-text="'mdi-pencil'"
                              small
                            />
                          </v-btn>
                          <v-btn
                            icon
                            small
                            :loading="loadingDelete === question.id"
                            @click="clickDeleteItem(question)"
                          >
                            <v-icon
                              v-text="'mdi-delete'"
                              small
                            />
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
      </v-sheet>
    </v-container>

    <dialog-delete
      v-model="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />

    <dialog-question
      v-model="showDialogQuestion"
      :survey-id="parseInt($route.params.id)"
      :question-id="editId"
      :key="`${editId}-${newKey}`"
      @save="loadQuestions"
    />

  </div>
</template>

<script>
  import surveysApi from '@/api/surveys'
  import questionsApi from '@/api/questions'

  export default {

    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogQuestion: () => import('@/components/dialog/questions/DialogQuestion'),
    },

    data () {
      return {
        loading: false,
        loadingDelete: false,
        showDialogDelete: false,
        showDialogQuestion: false,
        isSorting: false,
        deleteId: null,
        editId: null,
        survey: null,
        questions: [],
        newKey: 0,
      }
    },

    computed: {
      maxQuestionsId () {
        return Math.max(...this.questions.map(o => o.id), 0)
      }
    },

    mounted () {
      this.loadSurvey()
      this.loadQuestions()
    },

    methods: {

      async loadSurvey () {
        try {
          this.loading = true

          const response = await surveysApi.get(this.$route.params.id)

          this.survey = response.data.survey
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadQuestions () {
        try {
          this.loading = true

          const response = await questionsApi.list(this.$route.params.id)

          this.questions = response.data.questions
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialogQuestion (question = null) {
        if (!question) {
          this.newKey++
        }

        this.editId = question ? question.id : 0
        this.showDialogQuestion = true
      },

      async clickDeleteItem (question) {
        this.deleteId = question.id
        this.showDialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await questionsApi.delete(this.survey.id, this.deleteId)

          this.loadQuestions()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.showDialogDelete = false
        }
      },

    },

  }
</script>
